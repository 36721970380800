.has-text-align-center {
  text-align: center;
}

.has-text-align-right {
  text-align: right;
}

.page .page-head {
  font-size: 20px;
  line-height: 32px;
  font-family: 'Din';
  font-weight: normal;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .page .page-head {
    font-size: 24px;
    line-height: 36px;
    font-family: 'Din';
    font-weight: normal;
    margin-bottom: 40px;
  }
  .cookie {
    padding-right: 10px;
    display: flex;
  }

  .cookie .btn-container {
    margin-left: 80px;
  }

  .cookie .btn {
    position: relative;
    margin: 0.5rem;
    top: 0;
    right: 0;
    width: 200px;
    padding: 0 32px;
  }

  .cookie p {
    margin: auto;
  }
}

section.section.section-blue.live-feed {
  overflow: hidden;
}

#app-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

#app-content-container {
  overflow: auto;
  overflow-x: hidden;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.accept-cookie-container p {
  font-weight: bold;
  font-size: large;
  display: inline-block;
  margin-right: 2em;
  padding: 16px 0;
  line-height: 24px;
  vertical-align: top;
}

.accept-cookie-container .switch {
  display: inline-block;
}

.btn--linkedin {
  background-color: #0077b5;
}

.btn--linkedin:focus {
  background-color: rgb(0, 119, 181, 0.6);
}

.icon-linkedin:before {
  content: '\eac9';
}

/* Social network icons */
.icon-twitch:before {
  content: '';
  background-image: url('../img/twitch.svg');
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  display: block;
}

.mb-2 {
  margin-bottom: 16px;
}

.icon-snapchat:before {
  content: '';
  background-image: url('../img/snapchat.png');
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  display: block;
}

.icon-tik-tok:before {
  content: '';
  background-image: url('../img/tiktok.svg');
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  display: block;
}

.icon-twitter:before {
  content: '' !important;
  background-image: url('../img/x_twitter-grey.svg');
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  display: block;
}

.label .icon-twitter:before,
.is-dark .icon-twitter:before {
  background-image: url('../img/x_twitter.svg');
}

.label .icon-twitter:before {
  height: 12px;
  width: 12px;
}

.news--list {
  border-bottom: 1px solid $dark-border;
  padding-bottom: 24px;

  article {
    display: flex;
  }

  &:after {
    content: ' ';
    display: table;
    clear: both;
  }

  .news__thumbnail {
    position: relative;
    width: 111px;
    height: 76px;
    margin-right: 12px;
    float: left;
  }

  .news--list__left {
    display: flex;
    flex-direction: column;
  }

  .news__desc {
    color: #999;
    padding-bottom: 24px;
    margin-top: 8px;
  }

  .label {
    margin-bottom: 4px;
  }
}

@media (min-width: 768px) {
  .news--list {
    .news__desc {
      padding-bottom: 0;
    }
    .news__date {
      position: static;
      margin-top: 4px;
      margin-left: 5px;
    }
    .news__thumbnail {
      margin-right: 16px;
    }
    .news__title {
      margin-left: 132px;
    }
  }
}

@media (min-width: 1280px) {
  .news--list {
    border-bottom: 0;
    padding-bottom: 0;

    .news__thumbnail {
      width: 180px;
      height: 120px;
      margin-right: 32px;
      margin-bottom: 0;
    }
    .news__desc {
      margin-top: 4px;
      clear: none;
    }
    .news__content {
      min-height: 120px;
      padding-bottom: 24px;
      border-bottom: 1px solid #ebebeb;
    }
    .news__title,
    .news__date {
      margin-left: 0;
    }
  }
}

.page h1,
.page h2,
.page h3,
.page h4,
.page h5,
.page h6 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.footer__corporate-links .accept-cookie-container {
  color: rgba(255, 255, 255, 0.6);
}

.footer__corporate-links .accept-cookie-container:hover {
  text-decoration: underline;
  color: #fff;
  cursor: pointer;
}

.display-desktop-only {
  display: none !important;
}

@media (min-width: 1080px) {
  .display-desktop-only {
    display: block !important;
  }

  .mobile-tablet-only {
    display: none !important;
  }
}

/* C3PR et nouvelles topbar */
.btn--red-dark {
  background-color: #f2362f;
}

.topbar .btn--red-dark {
  line-height: 32px;
  height: unset;
  display: block;
  float: right;
  font-size: 13px;
  font-weight: 500;
  margin-right: 16px;
  margin-top: 4px;
}

body {
  /** Fix pour que la nav puisse être sticky */
  overflow: visible !important;
}
.topbar {
  position: sticky !important;
  top: 0;
  z-index: 11 !important;
}

.topbar__login {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 1079px) {
  body {
    /** Fix pour que la nav puisse être sticky */
    overflow: visible !important;
  }
  #root > .topbar:not(#topbar--mobile) {
    display: none;
  }

  .dropdown__wrapper {
    overflow-x: hidden;
  }

  .dropdown .dropdown__list {
    max-width: 100%;
  }

  .card--news-direct .dropdown .dropdown__head {
    padding-right: 16px;
  }

  #topbar--mobile {
    color: white;
    height: 36px;
    text-align: center;
    position: sticky !important;
    top: 0;
    z-index: 11 !important;
  }

  #topbar--mobile .topbar {
    z-index: 11 !important;
  }

  #topbar--mobile.topbar {
    padding: unset;
    background-color: unset;
    margin-bottom: unset;
    line-height: 32px;
  }

  #topbar--mobile.topbar li {
    margin-bottom: unset;
  }

  #topbar--mobile .topbar--mobile__left {
    background-color: #002348;
    flex-grow: 1;
  }

  .icon-chevron-down::after {
    content: '\e928';
    font-family: 'icon';
    font-size: 6px;
    margin-left: 6px;
    display: block;
  }

  #topbar--mobile .topbar--mobile__button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #topbar--mobile .topbar--mobile__left .topbar--mobile__links {
    text-align: left;
  }

  #topbar--mobile.topbar .topbar__right {
    width: 40%;
    max-width: 180px;
  }

  #topbar--mobile > ul:nth-child(1) {
    display: flex;
  }

  .topbar--mobile__button {
    width: 100%;
    color: white;
  }

  .topbar--mobile__links {
    display: none;
  }

  .topbar--mobile__links.is-visible {
    display: block;
    position: absolute;
  }

  .topbar--mobile__links.is-visible li {
    line-height: 42px;
  }

  .topbar--mobile_important-link {
    background-color: #f2362f;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 4px;
    width: 30%;
  }

  .topbar--mobile_important-link a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #topbar--mobile .topbar__right {
    background-color: #7d93a8;
    display: block;
  }

  #topbar--mobile .topbar__login {
    margin-top: unset;
    margin-right: unset;
    background-color: #7d93a8;
    text-wrap: nowrap;
  }

  .topbar__right .topbar__sub {
    width: 230px;
    position: absolute;
    right: 0;
    background-color: #7d93a8;
    line-height: 2;
    padding: 8px;
    color: rgba(255, 255, 255, 0.6);
  }
  .topbar__right .topbar__sub li {
    display: block;
  }
  .topbar__right .topbar__sub a {
    padding: 0;
  }
  .topbar__right i {
    top: -2px;
  }
  .topbar__right .topbar__sub {
    padding-bottom: 16px;
  }

  .topbar--mobile__left .accordion-trigger,
  .topbar--mobile__left .accordion-trigger.is-open {
    color: #7d93a8 !important;
  }

  .topbar__user {
    padding: 0 8px;
    color: #fff;
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-left: 1px solid rgba(255, 255, 255, 0.06);
  }
  .topbar__user img {
    margin-right: 16px;
    border-radius: 100%;
  }
  .topbar__login {
    display: inline-block;
    padding: 0;
    background: #1a395a;
    color: #fff;
    padding: 0 8px;
    border-radius: 4px;
    margin-right: 4px;
    margin-top: 4px;
  }
  .topbar__login:hover {
    background: #20456e;
  }

  .header__mobile {
    line-height: unset;
    text-align: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
  }

  .header__mobile > i {
    position: relative;
    right: unset;
  }

  .header__mobile div .icon.icon-menu {
    width: 72px;
    line-height: 72px;
    font-size: 20px;
    color: #7d93a8;
    cursor: pointer;
  }

  .header--transparent .header__mobile div .icon.icon-menu {
    color: white;
  }

  .header__mobile .input--search.input--expand {
    color: #7d93a8;
    position: relative;
    height: fit-content;
  }

  .header__mobile .input--search.input--expand.is-open {
    position: absolute;
    right: 0;
    height: 72px;
    top: 0;
  }

  .header__mobile__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header__mobile .icon.icon-place {
    display: inline-block;
    color: #7d93a8;
    font-size: 26px;
    margin-right: unset;
  }

  .header--transparent .header__mobile .icon.icon-place {
    color: white;
  }

  .menu__right {
    display: none;
  }

  .header__wrapper .topbar {
    display: none;
  }

  .header__wrapper .menu {
    padding-top: unset;
  }

  #topbar--mobile .accordion-trigger {
    padding-right: 22px;
    position: relative;
  }

  .topbar--mobile__left.is-open .icon-chevron-down::after,
  #topbar--mobile .accordion-trigger.is-open::after {
    content: '\e929';
  }

  #topbar--mobile .accordion-trigger:after {
    float: none;
    position: absolute;
    right: 8px;
    top: 0;
    line-height: unset;
  }
}

.card--user {
  height: 468px;
}

.card--user .card__body {
  height: 184px;
}

@media (max-width: 767px) {
  .header__wrapper.is-visible {
    top: 108px;
    height: calc(100% - 108px);
  }

  .card--user {
    height: 174px;
  }

  .card--user .card__thumbnail {
    height: 100%;
    background-position: top center;
    width: 84px;
  }

  .card--user .card__footer,
  .card--user .card__body {
    margin-left: 84px;
  }

  .card--user .card__body {
    height: 100%;
    width: calc(100% - 84px);
    padding: 8px 24px 8px;
  }

  .card--user .card__desc {
    font-size: 12px;
    line-height: 15px;
  }
  .card--user .card__title {
    margin-bottom: 4px;
    line-height: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: block;
    overflow: visible;
  }
  .card--user .card__footer {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 24px;
    padding-top: 8px;
  }

  .card--user .card__footer i {
    color: #7d93a8 !important;
  }
}
